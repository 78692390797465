import { createRoot } from 'react-dom/client';
import { FC, lazy, PropsWithChildren, StrictMode, Suspense } from 'react';
import { kcContext as kcLoginThemeContext } from './login/kcContext';
import { kcContext as kcAccountThemeContext } from './account/kcContext';

const KcLoginThemeApp = lazy(() => import("./login/KcApp"));
const KcAccountThemeApp = lazy(() => import("./account/KcApp"));

const App: FC<PropsWithChildren<{}>> = () => {
  return <StrictMode>
    <Suspense>
      {(()=>{

        if( kcLoginThemeContext !== undefined ){
          return <KcLoginThemeApp kcContext={kcLoginThemeContext} />;
        }

        if( kcAccountThemeContext !== undefined ){
          return <KcAccountThemeApp kcContext={kcAccountThemeContext} />;
        }

        throw new Error(
          "This app is a Keycloak theme" +
          "It isn't meant to be deployed outside of Keycloak"
        );

      })()}
    </Suspense>
  </StrictMode>
}

createRoot(document.getElementById("root")!).render(<App />);
